<template>
  <div>
    <!-- 批量操作弹窗 -->
    <el-dialog
      custom-class="addDialog"
      :visible.sync="batchDialog"
      :before-close="cancelSetCamera"
      :close-on-click-modal="false"
    >
      <div class="title" slot="title">
        批量操作
        <span class="sub-title">设置硬编解码</span>
      </div>

      <el-form :model="batchForm" :rules="rules" ref="ruleForm">
        <el-form-item label="开启硬编解码：" :required="true" label-width="180px" prop="accelerateHardware">
          <el-radio-group v-model="batchForm.accelerateHardware">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="码率："
          v-if="batchForm.accelerateHardware === true"
          :required="true"
          label-width="180px"
          prop="bitRate"
        >
          <el-cascader v-model="batchForm.bitRate" :options="options"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSetCamera">取 消</el-button>
        <el-button type="primary" @click="submit('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { bitrateOptions, closedtimeBitrateOptions } from 'src/utils/selectOptions'
import { computedDataRate } from 'src/utils/common'
import { upgrade } from 'src/api/legacy'

export default {
  data() {
    return {
      batchDialog: false, // 批量操作弹窗显示
      batchForm: {
        accelerateHardware: null,
        bitRate: ['closedtimeBitrate', 150],
      },
      rules: {
        accelerateHardware: [
          {
            required: true,
            message: '请选择',
            trigger: 'blur',
          },
        ],
        bitRate: [
          {
            required: true,
            message: '请选择',
            trigger: 'blur',
          },
        ],
      },
      options: [
        {
          value: 'closedtimeBitrate',
          label: '非营业时间码率',
          children: [...closedtimeBitrateOptions],
        },
        {
          value: 'dataRateUpperLimit',
          label: '营业时间码率',
          children: [...bitrateOptions],
        },
      ],
    }
  },
  props: {
    cameraIds: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    'batchForm.accelerateHardware'(val) {
      if (!val) {
        // 硬解码关闭时，清空码率
        this.batchForm.bitRate = ['closedtimeBitrate', 150]
      }
    },
  },
  methods: {
    /** 设置硬编解码 */
    open() {
      this.batchDialog = true
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName].resetFields()
    },
    cancelSetCamera() {
      this.batchDialog = false
      this.resetForm()
    },
    submit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.confirm()
        }
      })
    },
    confirm() {
      const html = `
      <div style="max-height: 300px; overflow: scroll;">
        <p>以下摄像头</p>
        ${this.cameraIds
          .map((item) => {
            return `<p>${item}</p>`
          })
          .join('')}
        <p>均
          ${this.batchForm.accelerateHardware ? '开启' : '关闭'}
          硬编解码
          ${this.batchForm.accelerateHardware ? '，且码率均设为 ' + computedDataRate(this.batchForm.bitRate[1]) : ''}
        </p>
      </div>
      `
      this.$confirm(html, '确定保存吗？', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      }).then(() => {
        this.batchUpdateHardConfig()
      })
    },
    batchUpdateHardConfig() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      upgrade
        .batchUpdateHardConfig({
          cameraIds: this.cameraIds,
          enabled: this.batchForm.accelerateHardware,
          [this.batchForm.bitRate[0]]: this.batchForm.bitRate[1],
        })
        .then((res) => {
          if (res.status === 0) {
            this.$message({
              message: '保存成功',
              type: 'success',
            })
            this.batchDialog = false
            this.resetForm()
            this.$emit('update')
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
        })
        .finally(() => {
          loading.close()
        })
    },
  },
}
</script>
<style scoped lang="less">
.title {
  font-size: 18px;
  .sub-title {
    margin-left: 10px;
    font-size: 14px;
    color: #ccc;
  }
}
</style>
