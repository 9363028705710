<template>
  <div class="container">
    <div>
      <el-table style="width: 100%" v-loading="listLoading" :data="tableData">
        <el-table-column type="index" :index="noindexTable" align="right" show-overflow-tooltip label="序号"></el-table-column>
        <el-table-column prop="macId" align="right" show-overflow-tooltip label="NVRMacID"></el-table-column>
        <el-table-column prop="name" align="right" show-overflow-tooltip label="设备名称"></el-table-column>
        <el-table-column prop="ip" align="right" show-overflow-tooltip label="IP地址"></el-table-column>
        <el-table-column prop="boxMac" align="right" show-overflow-tooltip label="盒子MacID"></el-table-column>
        <el-table-column prop="boxPort" align="right" show-overflow-tooltip label="盒子端口号"></el-table-column>
        <el-table-column prop="channelCnt" align="right" show-overflow-tooltip label="通道数"></el-table-column>
        <el-table-column prop="vendor" align="right" show-overflow-tooltip label="厂商"></el-table-column>
        <el-table-column prop="model" align="right" show-overflow-tooltip label="型号"></el-table-column>
        <el-table-column prop="updateTime" align="right" show-overflow-tooltip label="操作时间"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200">
          <template slot-scope="scope">
            <el-button @click="goRoute(scope.row,'retail-traffic-device-nvrManage-setting')" class="unbind" type="text" size="small">设置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :currentPage="currentPage"
        :total="total"
        :pageSize="pageSize"
        @size="handleSizeChange"
        @current="handleCurrentChange"
      ></pagination>
    </div>
  </div>
</template>

  <script>
import Pagination from 'src/components/Pagination'
import { getNvrList } from 'src/api/nvr'

export default {
  components: {
    Pagination,
  },
  data() {
    const { productId } = this.$route.query
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      listLoading: true,
      productId,
      dataSource: [],
    }
  },
  computed: {
    tableData() {
      return this.dataSource.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  created() {
    this.handleListQuery()
  },
  methods: {
    noindexTable(index) {
      if (typeof index === 'undefined'){
        return 0
      } 
      return (this.currentPage - 1) * this.pageSize + index + 1
    },
    handleListQuery() {
      this.currentPage = 1
      this.queryList()
    },
    formatQueryData() {
      return {
        productId: this.productId,
      }
    },
    async queryList() {
      this.listLoading = true
      const params = this.formatQueryData()
      try {
        const res = await getNvrList(params)
        this.dataSource = res.data || []
        this.total = res.data?.length || 0
      } finally {
        this.listLoading = false
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    goRoute(row, name) {
      this.$router.push({
        name,
        query: {
          productId: this.productId,
          macId: row.macId
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 12px;
  .operation {
    display: flex;
    justify-content: space-between;
    // padding-top: 20px;
  }
}
</style>