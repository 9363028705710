import { updateFloorNum, batchUpdateLiveVideoOnly } from 'src/api/product'
import { floorOptions } from 'src/utils/selectOptions'

export default {
  data() {
    return {
      floorOptions,
    }
  },
  methods: {
    updateFloorNum(row) {
      const { cameraId, floorNum } = row
      const params = {
        cameraId,
        floorNum,
      }
      updateFloorNum(params).then(res => {
        if(res.status === 0){
          this.$message.success('修改成功')
        }
      })
    },
    UpdateLiveVideoOnly(row) {
      const { cameraId, liveVideoOnly } = row
      const params = {
        cameraIds: [cameraId],
        enabled: liveVideoOnly,
      }
      batchUpdateLiveVideoOnly(params).then(res => {
        if(res.status === 0){
          this.$message.success('修改成功')
        }
      })
    },
  },
}
