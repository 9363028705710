<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <div class="sub-title">
        {{ title.name }}
        <span class="box-no">{{ title.sub }}</span>
      </div>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="上传速度上限" prop="maxSpeed" :label-width="formLabelWidth">
          <el-input
            v-model="form.maxSpeed"
            autocomplete="off"
            :placeholder="isBatch ? '请输入正整数或者-1' : '请输入'"
            :maxlength="7"
            @input="handleSpeedLimitInput"
          >
            <template slot="append">K</template>
          </el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <div style="margin-top: 0px">
            <el-radio-group v-model="maxSpeed" size="mini" @input="handleOptionChange">
              <el-radio-button label="1024"></el-radio-button>
              <el-radio-button label="2048"></el-radio-button>
              <el-radio-button label="4096"></el-radio-button>
              <el-radio-button label="-1">不限速</el-radio-button>
            </el-radio-group>
          </div>
          <el-tag type="info">说明：若要设置不限速，值填为-1即可</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="确定修改吗？"
      :visible.sync="confirmDialogVisible"
      width="30%"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="content">
        <div>以下盒子</div>
        <div class="boxs">
          <div class="boxNo" v-for="(item, index) in boxs" :key="index">
            <span>{{ item }}</span>
          </div>
        </div>
        <div>营业时间上传速度上限改为{{ formatSpeedLimit(form.maxSpeed, true) }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="isSubmitting" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { solution } from 'src/api/product'
import { formatSpeedLimit } from 'src/utils/common'

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isBatch: {
      type: Boolean,
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('必填，且为正整数或者-1'))
      }
      if (!/^([1-9]\d*|-1)$/.test(value)) {
        return callback(new Error('必填，且为正整数或者-1'))
      }
      return callback()
    }

    return {
      form: {
        maxSpeed: '',
      },
      rules: {
        maxSpeed: [{ validator: validatePass, trigger: 'change', required: true }],
      },
      formLabelWidth: '120px',
      maxSpeed: undefined,
      isSubmitting: false,
      confirmDialogVisible: false,
      boxs: '',
    }
  },
  computed: {
    title() {
      if (this.isBatch) {
        return {
          name: '批量操作',
          sub: '设置营业时间上传速度上限',
        }
      }
      return {
        name: '设置营业时间上传速度上限',
        sub: this.data[0]?.boxNo,
      }
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (!this.isBatch) {
          this.form.maxSpeed = this.data[0]?.opentimeMaxSpeed
          this.maxSpeed = this.form.maxSpeed
        }
      } else {
        this.handleReset()
      }
    },
  },

  methods: {
    formatSpeedLimit,
    handleSpeedLimitInput(val) {
      if (val !== this.maxSpeed) {
        this.maxSpeed = undefined
      }
    },
    handleOptionChange(val) {
      this.form.maxSpeed = val
    },
    handleCancel() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.boxs = this.data.map((item) => item.boxNo)
          this.confirmDialogVisible = true
        }
      })
    },
    async handleSubmit() {
      try {
        this.isSubmitting = true
        const { data } = await solution.batchSetSpeedLimit({
          maxSpeed: this.form.maxSpeed,
          macIds: this.data.map((item) => item.macId),
        })
        if (data) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.confirmDialogVisible = false
          this.$emit('confirm')
        }
      } catch (error) {
        this.$message.error(`修改失败`)
      } finally {
        this.isSubmitting = false
      }
    },
    handleClose() {},
    handleReset() {
      this.boxs = ''
      this.form.maxSpeed = ''
      this.maxSpeed = undefined
      this.$refs.form.clearValidate()
    },
  },
}
</script>

<style lang="less" scoped>
.sub-title {
  line-height: 20px;
  font-size: 16px;
  color: #303133;
  font-weight: 700;
  margin-bottom: 12px;
  .box-no {
    margin-left: 8px;
    font-size: 14px;
    font-weight: normal;
  }
}
/deep/ .el-dialog__header {
  border-bottom: none;
}
.boxs {
  margin: 12px 0;
  .boxNo {
    margin-bottom: 4px;
  }
}
</style>