<template>
  <div class="container">
    <div class="search mt10">
      <div class="row">
        <el-input
          placeholder="请输入摄像头MacID、Camera ID、摄像头名称、盒子MacID、端口号检索"
          v-model="search.keyword"
          style="width: 750px"
          class="input-with-select mr10"
        ></el-input>
      </div>
      <div class="row">
        <label>在线状态：</label>
        <el-radio-group v-model="search.online">
          <el-radio :label="null">不限</el-radio>
          <el-radio :label="true">在线</el-radio>
          <el-radio :label="false">离线</el-radio>
        </el-radio-group>
      </div>
      <!-- <div class="row">
        <label>计算码流：</label>
        <el-radio-group v-model="search.camDataRate">
          <el-radio :label="null">不限</el-radio>
          <el-radio label="MAJOR">主码流</el-radio>
          <el-radio label="MINOR">次码流</el-radio>
        </el-radio-group>
      </div> -->
      <div class="row">
        <label>业务程序：</label>
        <span :class="!search.camBusinessAppList.length ? 'active' : ''" @click="noLimitBusiness">不限</span>
        <el-checkbox-group v-model="search.camBusinessAppList">
          <el-checkbox v-for="(item, index) in businessList" :key="index" :label="item.value">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div>
        <div class="row">
          <label>区域配置：</label>
          <span :class="!search.camAreaConfigList.length ? 'active' : ''" @click="noLimitArea">不限</span>
          <el-checkbox-group v-model="search.camAreaConfigList">
            <el-checkbox v-for="(item, index) in areaList" :key="index" :label="item.value">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row">
        <label>开启硬编解码：</label>
        <el-radio-group v-model="search.accelerateHardware">
          <el-radio :label="null">不限</el-radio>
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label>
          <span>客流计算</span>
          <el-tooltip class="item" effect="light" content="参与边缘计算" placement="top-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
          <span>：</span>
        </label>
        <el-radio-group v-model="search.edgeBox">
          <el-radio :label="null">不限</el-radio>
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
      <div class="row">
        <label><span>所属楼层/区域：</span></label>
        <el-select v-model="search.floorNum" style="width: 90px" @change="handleFloorChange(scope.row)">
          <el-option v-for="item in floorQueryOptions" :label="item.label" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </div>
      <div class="row" v-if="showNvr">
        <label><span>拉流方式：</span></label>
        <el-select v-model="search.streamSource" style="width: 90px">
          <el-option label="全部" :value="undefined" :key="undefined"></el-option>
          <el-option v-for="item in streamSourceOptions" :label="item" :value="item" :key="item"></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex alignCenter">
      <el-button type="primary" @click="handleListQuery">查询</el-button>
      <el-button class="ml30" @click="resetSearch">清空条件</el-button>

      <el-popover
        class="popover"
        placement="bottom-end"
        width="auto"
        trigger="hover"
        :popper-options="{ boundariesElement: 'viewport' }"
      >
        <div class="option-list">
          <span class="item" @click="setHardware">设置硬编解码</span>
        </div>
        <el-button slot="reference" style="float: right" type="primary">
          批量操作
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
      </el-popover>
    </div>
    <el-table
      class="mt10"
      v-loading="listLoading"
      :data="tableData"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" :selectable="selectable" fixed width="55"></el-table-column>

      <template v-for="(col, index) in columns">
        <el-table-column
          :key="index"
          :min-width="col.width"
          :prop="col.prop"
          :label="col.label"
          :sortable="col.sortable"
          :fixed="col.fixed"
          :show-overflow-tooltip="col.showOverflowTooltip"
        >
          <template slot="header" slot-scope="scope">
            <template v-if="col.prop === 'bitRate'">
              <span>{{ col.label }}</span>
              <el-tooltip effect="light" content="营业时间/非营业时间">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template v-else>{{ col.label }}</template>
          </template>
          <template slot-scope="scope">
            <template v-if="col.prop === 'snapshotPath'">
              <img :src="scope.row.snapshotPath" @click="enlargeImg(scope.row.snapshotPath)" class="screenshot" />
            </template>
            <template v-else-if="col.prop === 'customerName'">
              <span>{{ isEmpty(scope.row.customerName) ? '--' : scope.row.customerName }}</span>
            </template>
            <template v-else-if="col.prop === 'liveVideoOnly'">
              <span>{{ scope.row.liveVideoOnly===1 ? '仅播放视频' : '否' }}</span>
            </template>
            <template v-else-if="col.prop === 'shopName'">
              <span>{{ isEmpty(scope.row.shopName) ? '--' : scope.row.shopName }}</span>
            </template>
            <template v-else-if="col.prop === 'floorNum'">
              <el-select v-model="scope.row.floorNum" style="width: 90px" @change="handleFloorChange(scope.row)">
                <el-option
                  v-for="item in floorOptions"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </template>
            <div v-else-if="col.prop === 'status'"><status :data="scope.row.status" /></div>
            <div v-else-if="col.prop === 'accelerateHardware'">
              {{ scope.row.accelerateHardware ? '是' : '否' }}
            </div>
            <div v-else-if="col.prop === 'bitRate'">
              {{
                scope.row.accelerateHardware
                  ? computedDataRate(scope.row.hardVideo?.dataRateUpperLimit) +
                    '/' +
                    computedDataRate(scope.row.hardVideo?.closedtimeBitrate)
                  : '--'
              }}
            </div>
            <span v-else>{{ isEmpty(scope.row[col.prop]) ? '--' : scope.row[col.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button v-if="scope.row.streamSource !== 'NVR'" type="text" size="small" @click="linkSetting(scope.row)">设置</el-button>
          <el-button v-if="scope.row.streamSource !== 'NVR'" type="text" size="small" @click="handleCheck(scope.row)">查看</el-button>
          <el-button type="text" size="small" @click="handleLive(scope.row)">直播</el-button>
          <el-button v-if="scope.row.streamSource !== 'NVR'" type="text" size="small" @click="toDeviceApp(scope.row)">设备信息</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :currentPage="pageOptions.pageNumber"
      :total="total"
      :pageSize="pageOptions.pageSize"
      @size="handleSizeChange"
      @current="handleCurrentChange"
    ></pagination>

    <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" title="预览" :before-close="handleClose">
      <img :src="bigImgUrl" class="bigImgUrl" />
    </el-dialog>

    <camera-setting ref="cameraSetting" :cameraIds="cameraIds" @update="handleListQuery"></camera-setting>
  </div>
</template>

  <script>
import Pagination from 'src/components/Pagination'
import { businessList, areaList } from './common'
import { getCameraList } from 'src/api/product'
import { cameraColumns } from 'src/utils/table'
import { formatLabel, generateTableData, computedDataRate } from 'src/utils/common'
import { isEmpty } from 'src/utils/method'
import Status from 'src/components/Status'
import CameraSetting from './camera-setting.vue'
import cameraMixin from './cameraMixin'
import { floorOptions } from 'src/utils/selectOptions'
const objColumns = {
    prop: 'streamSource',
    label: '拉流方式',
    sortable: true,
    width: 110,
    showOverflowTooltip: true
  }
export default {
  mixins: [cameraMixin],
  components: {
    Pagination,
    Status,
    CameraSetting,
  },
  data() {
    const { productId, shopId } = this.$route.query
    return {
      computedDataRate,
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      listLoading: true,
      search: {
        keyword: '',
        online: null,
        // camDataRate: null,
        accelerateHardware: null,
        camBusinessAppList: [],
        camAreaConfigList: [],
        edgeBox: null,
        floorNum: undefined,
        streamSource: undefined
      },
      streamSourceOptions: ['盒子','NVR','流媒体'],
      businessList,
      areaList,
      floorQueryOptions: [
        {
          value: undefined,
          label: '全部',
        },
        ...floorOptions,
      ],

      productId,
      shopId,
      tableData: [],
      dataSource: [],
      imgDialog: false,
      bigImgUrl: null,
      cameraIds: [],
    }
  },
  computed: {
    showNvr() {
      return this.$route.name === 'retail-traffic-device-index'
    },
    columns() {
      let column = []
      if(this.showNvr) {
        cameraColumns.splice(2,objColumns)
        column = cameraColumns
      }else{
        column = cameraColumns.filter(item => item.prop !=='streamSource')
      }
      return column
    }
  },
  watch: {
    pageOptions: {
      handler() {
        this.formatTableData()
      },
      deep: true,
    },
  },
  created() {
    this.handleListQuery()
  },
  methods: {
    formatLabel,
    isEmpty,
    handleCheck(row) {
      const { path } = this.$route
      this.$router.push({
        name: /\/security\//.test(path) ? 'security-device-check' : 'retail-traffic-device-check',
        query: {
          cameraId: row.cameraId,
        },
      })
      sessionStorage.setItem('cameraInfo', JSON.stringify(row))
    },
    handleLive(row) {
      const { path } = this.$route
      this.$router.push({
        name: /\/security\//.test(path) ? 'security-device-live' : 'retail-traffic-device-live',
        query: {
          cameraid: row.cameraId,
        },
      })
    },
    handleFloorChange(row) {
      const floorNum = this.floorOptions.find((e) => e.value === row.floorNum).label
      const message = '摄像头所属楼层/区域修改为：' + floorNum
      this.$confirm(message, '确定修改吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.updateFloorNum(row)
        })
        .catch(() => {
          this.handleListQuery()
        })
    },
    linkSetting(row) {
      const { path } = this.$route
      const query = {
        cameraid: row.cameraId,
        from: 'device',
      }

      if (/^\/productManage/.test(path)) {
        this.$router.push({
          name: /\/security\//.test(path)
            ? 'security-device-cameraManage-setting'
            : 'retail-traffic-device-cameraManage-setting',
          query,
        })
      } else {
        this.$router.push({
          path: '/box/query/setting',
          query,
        })
      }
    },
    noLimitBusiness() {
      this.search.camBusinessAppList = []
    },
    noLimitArea() {
      this.search.camAreaConfigList = []
    },
    handleListQuery() {
      this.pageOptions.pageNumber = 1
      this.queryList()
    },
    formatTableData() {
      this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
    },
    formatQueryData() {
      return {
        shopId: this.shopId,
        productId: this.productId,
        ...this.search,
        camBusinessAppList: this.search.camBusinessAppList.join(','),
        camAreaConfigList: this.search.camAreaConfigList.join(','),
      }
    },
    async fetchList() {
      this.listLoading = true
      const params = this.formatQueryData()
      try {
        const res = await getCameraList(params)
        this.dataSource = res.data || []
        this.total = res.data?.length || 0
        this.formatTableData()
      } finally {
        this.listLoading = false
      }
    },
    toDeviceApp(row) {
      const url = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      const accessToken = localStorage.getItem('accessToken')
      window.open(
        url + '?accessToken=' + accessToken + '&username=' + username + '&cameraId=' + row.cameraId + '&boxType=0'
      )
    },
    resetSearch() {
      this.search.keyword = ''
      this.search.online = null
      // this.search.camDataRate = null
      this.search.camBusinessAppList = []
      this.search.camAreaConfigList = []
      this.search.accelerateHardware = null
      this.search.ownTag = null
      this.search.edgeBox = null
      this.search.floorNum = undefined
      this.search.streamSource = undefined

      this.handleListQuery()
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    queryList() {
      this.fetchList(this.formatQueryData(this.listQuery))
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
      this.queryList()
    },
    handleSelectionChange(row) {
      this.cameraIds = row.map((item) => item.cameraId)
    },
    selectable(row){
      return row.streamSource!=='NVR'
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
    handleClose(done) {
      done()
    },
    setHardware() {
      if (!this.cameraIds.length) {
        this.$message({
          message: '请先选择摄像头',
          type: 'warning',
        })
        return
      }
      this.$refs.cameraSetting.open()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

.container {
  padding: 0 12px;
}
.search .row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.search .row > label {
  font-size: 14px;
  font-weight: bold;
  /* margin-right: 20px; */
  width: 110px;
  text-align: right;
  flex: none;
  & + span {
    flex: none;
  }
}
.search .row > span {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
/deep/ .search .row > span.active {
  color: @linkColor;
}
/deep/ .search .el-radio__input {
  display: none;
}
/deep/ .search .el-checkbox__input {
  display: none;
}
/deep/ .search .el-radio {
  margin-right: 0;
}
/deep/ .search .el-radio-group {
  line-height: normal;
  vertical-align: inherit;
  font-size: inherit;
}
/deep/ .search .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
/deep/ .search .el-checkbox-group .el-checkbox {
  margin-right: 8px;
}
.screenshot {
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

.alignCenter {
  .popover {
    flex: 1;
    margin-left: 10px;
  }
}
.el-popover {
  .option-list {
    .item {
      cursor: pointer;
      &:hover {
        color: #5c76cc;
      }
    }
  }
}

/** 图片预览弹窗 */
.imgDialog .el-dialog__headerbtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c2c2c2;
  right: 10px;
  top: 10px;
}

.imgDialog .el-dialog__headerbtn .el-icon-close {
  color: #333;
}

.imgDialog .el-dialog__body {
  padding: 0;
}

.imgDialog .bigImgUrl {
  width: 760px;
  height: 600px;
  vertical-align: top;
}
</style>

<style>
/** overwrite */
.el-popover {
  min-width: auto;
}
</style>
