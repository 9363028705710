// 产品管理-客流/安防-设备管理-摄像头
export const cameraColumns = [
  {
    prop: 'mac',
    label: 'MacID',
    sortable: true,
    width: 130,
    showOverflowTooltip: true,
    fixed: true
  },
  {
    prop: 'cameraId',
    label: 'CameraID',
    sortable: true,
    width: 130,
    showOverflowTooltip: true,
    fixed: true
  },
  {
    prop: 'cameraName',
    label: '摄像头名称',
    sortable: true,
    width: 110,
    showOverflowTooltip: true
  },
  {
    prop: 'liveVideoOnly',
    label: '仅播放视频',
    sortable: true,
    width: 110,
    showOverflowTooltip: true
  },
  {
    prop: 'boxMac',
    label: '盒子MacID',
    sortable: true,
    width: 110,
    showOverflowTooltip: true
  },
  {
    prop: 'boxPort',
    label: '盒子端口号',
    sortable: true,
    width: 110,
    showOverflowTooltip: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: true,
    showOverflowTooltip: true
  },
  {
    prop: 'accelerateHardware',
    label: '开启硬编解码',
    width: 118,
    sortable: true,
    showOverflowTooltip: true
  },
  {
    prop: 'bitRate',
    label: '码率',
    width: 110,
    showOverflowTooltip: true
  },
  {
    prop: 'floorNum',
    label: '所属楼层/区域',
    width: 110,
  },
  {
    prop: 'snapshotPath',
    label: '底图',
    showOverflowTooltip: true
  },
]
